<template>
  <main>
    <Navbar></Navbar>
    <NavBox></NavBox>
    <div>
      <div style="padding: 30px; display: flex; justify-content: space-around">
        <van-button @click="search" type="primary">查询质押</van-button>
        <van-button @click="getdataList()" type="primary">查询所有数据</van-button>
        <van-button @click="createModalStatue = true" type="danger">添加自定义质押</van-button>
        <van-button @click="toShowError()" type="info">{{showErrorStatus ? '显示全部' : '显示有误的'}}</van-button>
      </div>
      <div class="tableBox">
        <div style="text-align: right">总行数： {{dataList.length}}</div>
        <table>
          <thead>
            <tr>
              <th>eth钱包</th>
              <th>信用分</th>
              <th>DeeperChain</th>
              <th>质押数量</th>
              <th>质押时间</th>
              <th>SN</th>
              <th>信用同步设置</th>
              <th>当前信用</th>
              <th>当前信用更新时间</th>
            </tr>
          </thead>
          <tbody>
            <tr @click="showItem(item)" v-for="item in showList" :key="item._id + 'r1'">
              <td>{{ item._id }}</td>
              <td>
                {{item.credit}}
                <div>{{item.userEarnCredit}}</div>
              </td>
              <td>
                {{item.deeperChain}}
              </td>
              <td>
                {{(item.dprAmount/1e18).toFixed(5)/1}}
              </td>
              <td>
                {{item.stakingTime/1 ? $moment(item.stakingTime / 1 * 1000).format() : ''}}
              </td>
              <td>
                {{item.sn}}
              </td>
              <td style="word-break: break-all;width: 200px">
                {{item.creditData}}
              </td>
              <td>
                {{item.creditItem && item.creditItem.credit}}
              </td>
              <td>
                {{item.creditItem ? $moment(item.creditItem.updatedAt).format() : ''}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <van-popup v-model="createModalStatue">
      <van-form style="width: 800px;" @submit="onSubmitCreate">
        <van-field
          v-model="createData.deeperChain"
          name="deeperChain"
          label="deeperChain"
          placeholder="deeperChain"
        />
        <van-field
          v-model="createData.sn"
          name="sn"
          label="sn"
          placeholder="sn"
        />
        <van-field
          v-model="createData.credit"
          name="credit"
          label="credit"
          placeholder="credit"
        />
        <van-field
          v-model="createData.creditData"
          name="creditData"
          label="creditData"
          placeholder="creditData"
          type='textarea'
          rows="10"
        />
        <van-field
          v-model="createData.needDPR"
          name="needDPR"
          label="needDPR"
          placeholder="needDPR"
        />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </van-popup>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar/NavbarAdmin";
import NavBox from '../NavBox.vue'
function cleanObj(obj) {
  let result = {};
  Object.keys(obj).forEach(key => {
    if(obj[key]) {
      result[key] = obj[key]
    }
  })
  return result
}
const parseNumberMap = {
  Zero: 0,
  One: 1,
  Two: 2,
  Three: 3,
  Four: 4,
  Five: 5,
  Six: 6,
  Seven: 7,
  Eight: 8,
  Nine: 9
}
export default {
  components: {
    Navbar,
    NavBox
  },
  data() {
    return {
      dataList: [],
      createModalStatue: false,
      showErrorStatus: false,
      createData: {
        deeperChain: '',
        sn: '',
        dprAmount: '',
        creditData: '',
        needDPR: ''
      },
    };
  },
  mounted() {

  },
  computed: {
    showList() {
      if (this.showErrorStatus) {
        return this.dataList.filter(it => {
          if (it.creditData && it.creditData.credit/1 > it.creditItem.credit/1 && it.stakingTime * 1000 > Date.now() - 7 * 3600 * 1000) {
            return true
          }
          return false
        })
      }
      return this.dataList
    }
  },
  methods: {
    toShowError() {
      this.showErrorStatus = !this.showErrorStatus
    },
    showItem(item) {
      console.log(item)
    },
    search() {
      let searchParams = prompt();
      return this.$fetch({
        url: "/admin/stakingList",
        params: {
          search: searchParams
        },
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
      }).then((res) => {
        this.dataList = res.data;
      }).finally(() => {
      })
    },
    onSubmitCreate() {
      let creditData = {}
      let creditDataStr = this.createData.creditData.replace(/"/g, '').replace(/,/g, '').split('\n')
      console.log(creditDataStr)
      creditDataStr.filter(it => it.match(':')).forEach(it => {
        let paramData = it.split(':')
        if ((paramData[1].trim()/1).toString() !== 'NaN') {
          creditData[paramData[0].trim()] = paramData[1].trim()/1
        } else if (parseNumberMap[paramData[1].trim()] !== undefined) {
          creditData[paramData[0].trim()] = parseNumberMap[paramData[1].trim()]
        } else {
          creditData[paramData[0].trim()] = paramData[1].trim()
        }
      })
      this.$fetch({
        url: "/admin/setStaking",
        method: 'POST',
        data: {
          ...this.createData,
          creditData
        },
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
      }).then((res) => {
        console.log(res)
        if (res.success) {
          this.$Toast.success()
          this.createModalStatue = false
        }
      })
    },
    getdataList() {
      this.$fetch({
        url: "/admin/stakingList",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
      }).then((res) => {
        this.dataList = res.data;
      })
    },
  },
};
</script>

<style lang="less" scoped>
.searchBox {
  padding: 10px 30px;
  .leftBox {
    display: flex;
  }
  .rightBox {
    display: flex;
    .formItem {
      margin-left: 30px;
      display: flex;
      align-items: center;
      .titleBox {
        margin-right: 10px;
      }
    }
  }
}
.tableBox {
  padding: 30px;
  font-size: 12px;
  text-align: center;
  table {
    width: 100%;
    th,td {
      padding: 5px 2px;
    }
  }
}

.addressBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
}
</style>